<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <h4>Links Personalizados</h4>
        <b-col class="d-flex justify-content-end mr-4">
          <b-button variant="primary" @click="showCreateLinkModal">
            NOVO LINK
          </b-button>
        </b-col>
        <b-table
          :items="promotionalLinks"
          :fields="promotionalLinksFields"
          bordered
          striped
          hover
          mouse-pointer
          class="mt-2"
        >
          <template #cell(expireDate)="row">
            {{ new Date(row.item.expireDate).toLocaleString("pt-BR") }}
          </template>

          <template #cell(link)="row">
            {{ row.item.link }}

            <button
              title="Copiar Link"
              class="button"
              @click="copyLink(row.item.link)"
            >
              <b-icon icon="files"></b-icon>
            </button>
          </template>

          <template #cell(actions)="row">
            <b-button
              size="sm"
              :title="
                row.item.active
                  ? 'Desativar link promocional'
                  : 'Ativar link promocional'
              "
              :variant="row.item.active ? 'danger' : 'success'"
              @click="activatePromotionalLink(row.item)"
              ><b-icon
                :icon="row.item.active ? 'x-circle' : 'check-circle'"
              ></b-icon
            ></b-button>
            <b-button
              size="sm"
              class="ml-1"
              title="Anexar produto a esse link"
              variant="primary"
              @click="
                showAddProductToPromotionLinkModal(row.item.id);
                getProductsOfPromotion(row.item.id);
              "
            >
              <b-icon icon="plus"></b-icon>
            </b-button>
            <b-button
              size="sm"
              class="ml-1"
              title="Listar produtos anexados"
              variant="primary"
              @click="showListProductsOfPromotionModal(row.item.id)"
            >
              <b-icon icon="list-check"></b-icon>
            </b-button>

            <b-button
              variant="primary"
              size="sm"
              class="ml-1"
              title="Editar link"
              @click="
                showEditPromotionalLinkModal({
                  id: row.item.id,
                  name: row.item.name,
                  expireDate: row.item.expireDate,
                })
              "
            >
              <b-icon icon="pencil"></b-icon>
            </b-button>

            <b-button
              size="sm"
              title="Deletar link"
              variant="danger"
              class="ml-1"
              @click="showConfirmRemoveLinkModal(row.item, row.index)"
            >
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>

        <b-modal
          v-model="editPromotionalLinkModal"
          title="Editar link"
          size="lg"
          hide-footer
        >
          <b-form @submit.prevent="updatePromotionalLink">
            <form-input
              v-model="updatePromotionalLinkForm.name"
              label="Descrição:"
              placeholder="Insira o nome da promoção"
              required
              icon="pencil"
              :errors="errors['name']"
            />
            <form-date
              v-model="updatePromotionalLinkForm.expireDate"
              class="mb-2"
              label="Data:"
              placeholder="Selecione uma data"
              hide-header
              :errors="errors['expireDate']"
            />
            <b-button variant="primary" type="submit">SALVAR</b-button>
          </b-form>
        </b-modal>

        <b-modal
          v-model="ListProductsOfPromotionModal"
          title="Produtos associados a esse link"
          size="xl"
          hide-footer
        >
          <b-table
            :items="addedProducts"
            :fields="listOfProductsInPromotionField"
          >
            <template #cell(actions)="row">
              <b-button
                size="sm"
                variant="danger"
                @click="showConfirmDeleteProductModal(row.item)"
              >
                <b-icon icon="x-circle"></b-icon>
              </b-button>
            </template>
          </b-table>
        </b-modal>

        <b-modal
          v-model="createLinkModal"
          title="Criar nova promoção"
          size="lg"
          hide-footer
        >
          <b-form @submit.prevent="createPromotionalLink">
            <form-input
              v-model="createPromotionalLinkForm.name"
              label="Descrição:"
              placeholder="Insira o nome da promoção"
              required
              icon="pencil"
              :errors="errors['name']"
            />

            <form-date
              v-model="createPromotionalLinkForm.expireDate"
              class="mb-2"
              label="Data:"
              placeholder="Selecione uma data"
              required
              :errors="errors['expireDate']"
            />

            <b-button class="mt-4" variant="primary" type="submit">
              CRIAR
            </b-button>
          </b-form>
        </b-modal>

        <b-modal
          v-model="addProductToPromotionLinkModal"
          title="Adicione produtos a essa promoção"
          size="xl"
          hide-footer
        >
          <form-input
            @enter="searchProducts"
            icon="search"
            label="Pesquisar produtos"
            v-model="search"
          ></form-input>
          <b-table
            :items="listProducts"
            :fields="productTableFields"
            :busy="isBusy"
          >
            <template #cell(actions)="row">
              <b-button
                v-if="!compareProductIdInAddedProduct(row.item.id)"
                size="sm"
                title="Adicinar produto"
                variant="success"
                @click="addProductInPromotion(row.item)"
              >
                <b-icon icon="check-circle"></b-icon>
              </b-button>
              <b-button
                v-else
                size="sm"
                title="Remover produto"
                variant="danger"
                @click="removeProductInPromotion(row.item.id)"
              >
                <b-icon icon="x-circle"></b-icon>
              </b-button>
            </template>

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Carregando...</strong>
              </div>
            </template>
          </b-table>

          <b-button
            v-if="button"
            variant="primary"
            block
            @click="searchMoreProducts"
          >
            <b-spinner
              v-if="loadMoreButton"
              small
              class="align-middle"
            ></b-spinner>

            CARREGAR MAIS</b-button
          >
        </b-modal>

        <custom-modal
          v-model="confirmDeleteModal"
          title="Deseja retirar o produto da promoção?"
          :message="`Tem certeza que deseja retirar o produto de codigo: ${productCode}`"
          @confirm="removeProductInPromotion(idProduct)"
        >
        </custom-modal>
        <custom-modal
          v-model="confirmRemoveLinkModal"
          title="Deseja remover o link?"
          :message="`Deseja remover o link promocional '${deleteLinkInfo.name}'?`"
          @confirm="deletePromotionalLink()"
        >
        </custom-modal>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import http from "../../plugins/http";
import FormInput from "../../components/form/FormInput.vue";
import FormDate from "../../components/form/FormDate.vue";
import CustomModal from "../../components/shared/CustomModal.vue";
import { mapMutations } from "vuex";

export default {
  components: { FormInput, CustomModal, FormDate },
  data() {
    return {
      search: "",
      page: 2,
      button: true,
      promotionId: 0,
      confirmDeleteModal: false,
      confirmRemoveLinkModal: false,
      idProduct: 0,
      productCode: "",
      promotionalLinks: [],
      listProducts: [],
      ListProductsOfPromotionModal: false,
      addedProducts: [],
      createLinkModal: false,
      addProductToPromotionLinkModal: false,
      editPromotionalLinkModal: false,
      isBusy: false,
      loadMoreButton: false,
      errors: {},
      deleteLinkInfo: {
        id: "",
        index: 0,
        name: "",
      },
      createPromotionalLinkForm: {
        name: "",
        expireDate: "",
      },
      updatePromotionalLinkForm: {
        id: "",
        name: "",
        expireDate: "",
      },
      promotionalLinksFields: [
        {
          key: "name",
          label: "Descrição",
          sortable: true,
        },
        {
          key: "link",
          label: "URL",
          sortable: true,
        },
        {
          key: "expireDate",
          label: "Data para expirar",
          sortable: true,
          thStyle: { width: "200px !important" },
        },
        {
          key: "actions",
          label: "Ações",
          thStyle: { width: "250px !important" },
        },
      ],
      productTableFields: [
        {
          key: "productCode",
          label: "Código",
        },
        {
          key: "completeDescription",
          label: "Descriçao",
        },
        {
          key: "actions",
          label: "Ações",
        },
      ],
      listOfProductsInPromotionField: [
        {
          key: "product.productCode",
          label: "Cod. Produto",
        },
        {
          key: "product.description",
          label: "Descrição",
        },
        {
          key: "actions",
          label: "Ações",
          thStyle: { width: "200px !important" },
        },
      ],
    };
  },

  methods: {
    ...mapMutations(["setOverlay", "setToastedError", "setToastedSuccess"]),

    copyLink(link) {
      navigator.clipboard.writeText(link);
    },

    showEditPromotionalLinkModal(item) {
      this.errors = {};
      this.updatePromotionalLinkForm.id = item.id;
      this.updatePromotionalLinkForm.name = item.name;
      this.updatePromotionalLinkForm.expireDate = item.expireDate;
      this.editPromotionalLinkModal = true;
    },

    showConfirmRemoveLinkModal(item, index) {
      this.deleteLinkInfo.index = index;
      this.deleteLinkInfo.id = item.id;
      this.deleteLinkInfo.name = item.name;
      this.confirmRemoveLinkModal = true;
    },
    showCreateLinkModal() {
      this.errors = {};
      this.createPromotionalLinkForm = {
        name: "",
        expireDate: "",
      };
      this.createLinkModal = true;
    },
    showAddProductToPromotionLinkModal(id) {
      this.search = "";
      this.listProducts = [];
      this.promotionId = id;
      this.addProductToPromotionLinkModal = true;
    },
    showListProductsOfPromotionModal(id) {
      this.getProductsOfPromotion(id);
      this.ListProductsOfPromotionModal = true;
    },
    compareProductIdInAddedProduct(productId) {
      return !!this.addedProducts.find(
        (value) => value.idProduct === productId
      );
    },
    showConfirmDeleteProductModal(item) {
      this.idProduct = item.idProduct;
      this.productCode = item.product.productCode;
      this.confirmDeleteModal = true;
    },
    async createPromotionalLink() {
      this.errors = {};
      try {
        const { data } = await http.post("/promotionalLinks/create", {
          name: this.createPromotionalLinkForm.name,
          expireDate: this.createPromotionalLinkForm.expireDate,
        });
        this.promotionalLinks.push(data);
        this.setToastedSuccess({ message: "Link promocional criado" });
        this.createLinkModal = false;
      } catch (error) {
        if (error.response.status === 406) {
          this.errors = error.response.data.error;
        } else {
          this.setToastedError({
            message: error.response.data.error,
          });
        }
      }
    },

    async updatePromotionalLink() {
      this.errors = {};
      try {
        const { data } = await http.put(
          `/promotionalLinks/update/${this.updatePromotionalLinkForm.id}`,
          {
            name: this.updatePromotionalLinkForm.name,
            expireDate: this.updatePromotionalLinkForm.expireDate,
          }
        );
        const index = this.promotionalLinks.findIndex(
          (element) => element.id === data.id
        );
        this.promotionalLinks.splice(index, 1, data);
        this.setToastedSuccess({ message: "Link atualizado" });
        this.editPromotionalLinkModal = false;
      } catch (error) {
        if (error.response.status === 406) {
          this.errors = error.response.data.error;
        } else {
          this.setToastedError({
            message: error.response.data.error,
          });
        }
      }
    },

    async activatePromotionalLink(item) {
      try {
        this.setOverlay(true);
        const { data } = await http.put(
          `/promotionalLinks/activate/${item.id}`,
          {
            activate: !item.active,
          }
        );
        const index = this.promotionalLinks.findIndex(
          (element) => element.id === data.id
        );
        this.promotionalLinks.splice(index, 1, data);

        if (data.active) {
          this.setToastedSuccess({ message: "Link ativado" });
        } else {
          this.setToastedSuccess({ message: "Link desativado" });
        }
      } catch (error) {
        this.setToastedError({
          message: error.response.data.error,
        });
      }

      this.setOverlay(false);
    },
    async deletePromotionalLink() {
      this.setOverlay(true);
      this.confirmRemoveLinkModal = false;
      await http.delete(`/promotionalLinks/delete/${this.deleteLinkInfo.id}`);
      this.promotionalLinks.splice(this.deleteLinkInfo.index, 1);
      this.setOverlay(false);
    },

    async searchProducts() {
      this.isBusy = true;
      const { data } = await http(
        `/product/search?search=${this.search}&page=1&show=1&active=1`
      );
      if (data.length !== 20) {
        this.button = false;
      } else {
        this.button = true;
      }

      this.page = 2;
      this.listProducts = data;
      this.isBusy = false;
    },

    async searchMoreProducts() {
      this.loadMoreButton = true;
      const { data } = await http(
        `/product/search?search=${this.search}&page=${this.page}&show=1&active=1`
      );
      if (data.length !== 20) {
        this.button = false;
      }
      this.listProducts.push(...data);
      this.page += 1;
      this.loadMoreButton = false;
    },

    async addProductInPromotion(item) {
      const { data } = await http.post(
        `/promotionalLinks/product/${this.promotionId}`,
        {
          idProduct: item.id,
        }
      );
      this.addedProducts.push(data);
    },

    async removeProductInPromotion(idProduct) {
      await http.delete(`/promotionalLinks/deleteProduct/${idProduct}`, {
        idPromotion: this.promotionId,
      });
      this.addedProducts = this.addedProducts.filter(
        (value) => value.idProduct !== idProduct
      );
      this.confirmDeleteModal = false;
    },

    async getProductsOfPromotion(id) {
      const { data } = await http(`/promotionalLinks/product/${id}`);
      this.addedProducts = data;
    },
  },
  async created() {
    this.setOverlay(true);
    const { data } = await http("/promotionalLinks/");
    this.promotionalLinks = data;
    this.setOverlay(false);
  },
};
</script>
<style scoped>
.button {
  outline: none;
  background: none;
  border: none;
}
</style>
